export const API_KEY = process.env.REACT_APP_API_URL;
export const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
export const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;
export const baseURL = process.env.REACT_APP_BASEURL;
export const INFURA_URL = process.env.REACT_APP_INFURA_URL;
export const INFURA_API_KEY = process.env.REACT_APP_INFURA_API_KEY;
export const ADMIN_WALLET = process.env.REACT_APP_ADMIN_WALLET;
export const PDF_PASSWORD = process.env.REACT_APP_PASSWORD;
export const PDF_PATH = process.env.REACT_APP_PDF_PATH;
